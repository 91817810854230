@tailwind base;
@tailwind components;
@tailwind utilities;



html{
    background-color: #000000;
}

video::-webkit-media-controls-overlay-play-button {
    display: none;
}